<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t("searchModule.Query_Table") }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option :label="$t('searchModule.all')" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <el-select
                  v-model.trim="formInline.closedParkType"
                  filterable
                  placeholder=""
                  size="10"
                >
                  <el-option :label="$t('searchModule.all')" value="0"></el-option>
                  <el-option
                    :label="park.desc"
                    :value="park.code"
                    :key="park.code"
                    v-for="park in parkType"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.is_malfunction')">
                <el-select
                  v-model.trim="formInline.equipmentStatus"
                  filterable
                  placeholder=""
                  size="10"
                >
                  <el-option :label="$t('searchModule.all')" value=""></el-option>
                  <el-option
                    :label="fault.desc"
                    :value="fault.code"
                    :key="fault.code"
                    v-for="fault in faultType"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()">{{
                $t("button.reset")
              }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <!--v-if="$route.meta.authority.button.detail"-->
          <el-table-column align="center" :label="$t('list.operation')" width="80">
            <template slot-scope="{ row }">
              <el-button
                v-if="$route.meta.authority.button.view"
                type="text"
                size="small"
                @click="handleCommand(row)"
                >{{ $t("button.view") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <equipment-detail ref="equipmentDetail"></equipment-detail>
  </div>
</template>
<script>
import { dateFormat, setIndex } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/autocompletaRoadside";
import EquipmentDetail from "./roadOutEquipmentDetailRoaddside.vue";
export default {
  name: "outside",
  data() {
    return {
      flag: false,
      plateNumber: "",
      rowData: {},
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [
        {
          code: 1,
          desc: this.$t("searchModule.floor"),
        },
        {
          code: 2,
          desc: this.$t("searchModule.under_floor"),
        },
        {
          code: 3,
          desc: this.$t("searchModule.all_floor"),
        },
      ],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      roleList: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "160",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "100",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          width: "140",
        },
        {
          prop: "closedParkType",
          label: this.$t("list.Type_of_parking_lot"),
          width: "100",
          formatter: (row, column) => {
            let type = {
              1: this.$t("list.floor"),
              2: this.$t("list.under_floor"),
              3: this.$t("list.all_floor"),
            };
            return type[row.closedParkType];
          },
        },
        {
          prop: "parkAddress",
          label: this.$t("list.address"),
          width: "300",
        },
        {
          prop: "equipmentStatus",
          label: this.$t("list.is_malfunction"),
          width: "100",
          formatter: (row, column) => {
            let status = {
              0: this.$t("list.yes"),
              1: this.$t("list.no"),
            };
            return status[row.equipmentStatus];
          },
        },
      ],
      tableData: [],
      faultType: [
        {
          code: 0,
          desc: this.$t("list.yes"),
        },
        {
          code: 1,
          desc: this.$t("list.no"),
        },
      ], // 故障车场
      parkDetail: [],
      parkRecordId: 0,
      parkRecordIdData: [],
      page: 1,
      size: 15,
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        closedParkType: "0",
        equipmentStatus: "",
      },
      areaIds: "",
    };
  },
  methods: {
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },

    handleCommand(data) {
      this.$refs.equipmentDetail.getDetails(data.parkId);
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        closedParkType: "0",
        equipmentStatus: "",
      };
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
      this.$refs.cascader.clear();
      this.searParkRecordListFun();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {
            page: 0,
            pageSize: 0,
            companyName: "",
            bearSide: "",
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
      // let opt = {
      //   method: 'get',
      //   url: '/acb/2.0/parkRecord/operation/list',
      //   data: {
      //     page: 0,
      //     pageSize: 0,
      //     companyName: '',
      //     bearSide: '',
      //   },
      //   success: res => {
      //     this.loading = false;
      //     if (res.state == 0) {
      //       this.roleList = res.value;
      //     } else {
      //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //         confirmButtonText: this.$t('pop_up.Determine'),
      //       });
      //     }
      //   },
      // };
      // this.$request(opt);
    },
    searParkRecordListFun() {
      // /thirdEquipment/parkList
      this.$axios
        .get("/acb/2.0/bacb/thirdEquipment/parkList", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            areaId: this.$refs.cascader.getStreetId()
              ? this.$refs.cascader.getStreetId()
              : this.$refs.cascader.getAreaId(),
            parkId: this.formInline.parkId,
            operationId: this.formInline.operationId,
            parkType: this.formInline.parkType,
            closedParkType: this.formInline.closedParkType, // 封闭停车场物理类型
            equipmentStatus: this.formInline.equipmentStatus,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
      // let opt = {
      //   method: 'get',
      //   url: '/acb/2.0/thirdEquipment/parkList',
      //   data: {
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize,
      //     areaId: this.formInline.streetId != '' ? this.formInline.streetId : this.formInline.areaId,
      //     parkId: this.formInline.parkId,
      //     operationId: this.formInline.operationId,
      //     parkType: this.formInline.parkType,
      //     closedParkType: this.formInline.closedParkType, // 封闭停车场物理类型
      //     equipmentStatus: this.formInline.equipmentStatus,
      //   },
      //   success: res => {
      //     this.loading = false;
      //     if (res.state == 0) {
      //       this.tableData = setIndex(this.pageNum, res.value.list);
      //       this.total = res.value.total * 1 || 0;
      //     } else {
      //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //         confirmButtonText: this.$t('pop_up.Determine'),
      //       });
      //     }
      //   },
      // };
      // this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          this.loading = true;
          this.flag = false;
          // this.$refs.parkInput.setShowVal(this.formInline.parkName);
          this.searParkRecordListFun();
        }
      });
    },
  },
  components: {
    myComponent,
    EquipmentDetail,
  },
  created() {
    this.getArea();
    this.getRoleList();
    // this.getExitType();
    // this.parkData()
    // this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.formInline.startTime = this.$route.query.bindTime
        ? this.$route.query.bindTime
        : this.$route.query.createdTime
        ? this.$route.query.createdTime
        : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.date1 = this.formInline.startTime;
      this.date2 = this.formInline.endTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1 ? "" : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
  },
  mounted() {
    this.searParkRecordList();
  },
  activated() {
    this.searParkRecordList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper {
  overflow: hidden;
  // padding: 22px 22px 0;
  // margin-bottom: 20px;
}

.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}
</style>
